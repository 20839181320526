<template>
  <TabDetailPage :menus="menus" :query="query"/>
</template>

<script>
import TabDetailPage from '@components/TabDetailPage.vue';

export default {
  components: {
    TabDetailPage,
  },
  data() {
    return {
      id: this.$route.query?.id,
      query: {
        id: this.$route.query?.id,
      },
    };
  },
  computed: {
    menus() {
      return [
        {
          id: '1',
          title: '基本信息',
          name: 'create',
          path: '/index/course/package/detail/basic',
        },
        {
          id: '2',
          title: '课程管理',
          name: 'detail',
          path: '/index/course/package/detail/courseList',
          disabled: !this.$route.query?.id,
        },
      ];
    },
  },
  watch: {
    $route(value) {
      this.query = {
        id: value.query.id,
      };
    },
  },
};
</script>
