<template>
  <a-form label-align="right"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 8 }"
          class="pt-10">
    <a-form-item label="课程达标配置">
      <a-input-number v-model="percentage"
                      :formatter="value => `${value}`.replace(/\D*/g, '')"
                      :parser="value => `${value}`.replace(/\D*/g, '')"
                      :min="1"
                      :max="100"
                      placeholder="达标百分比"
                      style="width: 100px;"/>
      <span class="ml-5">%</span>
    </a-form-item>
    <a-form-item label=" " :colon="false">
      <a-button type="primary" :loading="loading" @click="onOk">确认</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import {
  getDefaultCompleteConfig,
  setDefaultCompleteConfig,
} from './api';

export default {
  name: 'PassConfig',
  data() {
    return {
      loading: false,
      percentage: undefined,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const data = await getDefaultCompleteConfig();
      if (!data && data.error_code) {
        this.percentage = 98;
        this.$message.error(data?.message || '获取达标配置失败');
        return;
      }
      this.percentage = data.data.default_value;
    },
    onOk() {
      if (this.percentage <= 0) {
        this.$message.info('请输入正整数,不能小于0');
      }
      if (this.percentage > 100) {
        this.$message.info('请输入正整数,不能大于100');
      }
      this.setConfig();
    },
    async setConfig() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await setDefaultCompleteConfig({
        complete_percent: this.percentage,
      }).finally(() => {
        this.loading = false;
      });
      if (!data && data.error_code) {
        this.$message.error('保存失败', data.message);
        return;
      }
      this.$message.success('保存成功');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
