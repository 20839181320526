<template>
  <div class="course-package-create">
    <a-form-model labelAlign="left"
                  :label-col="{ span: 6 }"
                  :wrapper-col="{ span: 8 }"
                  label-align="right"
                  ref="modelRef"
                  :model="data"
                  :rules="rules">
      <a-form-model-item
        label="课程标题"
        prop="title">
        <a-input
          v-model="data.title"
          placeholder="请输入课程标题"
          width="500px"
        />
      </a-form-model-item>
      <a-form-model-item
        label="课包类型"
        prop="course_pack_type">
        <span class="flex">
          <a-input
            v-model="data.course_pack_type"
            placeholder="请输入课程包类型"
            :maxLength="2"/>
          <span>不得超过2个文字</span>
        </span>
      </a-form-model-item>
      <a-form-model-item
        label="课程包简介"
        prop="summary">
        <div class="upload-img">
          <a-textarea
            v-model="data.summary"
            placeholder="请输入课程包简介"
            @change="onChange"/>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="课程包封面图"
        prop="url">
        <div class="upload-img">
          <a-upload
            name="file"
            :multiple="true"
            :customRequest="upLoadImg"
            :showUploadList="false"
            :beforeUpload="onBeforeUpload">
            <img :src="data.url" v-if="data.url" class="upload-img"/>
            <a-button type="primary" v-if="!data.url"> 上传图片</a-button>
          </a-upload>
        </div>
      </a-form-model-item>
      <a-form-model-item label=" "
                         :colon="false"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 14 }">
        <a-button type="primary" @click="saveToNext"> 保存并下一步</a-button>
        <a-button @click="saveTo"> 保存本页</a-button>
        <a-button @click="onBack()"> 返回列表</a-button>
      </a-form-model-item>
    </a-form-model>
    <BlockLoading v-if="loading"></BlockLoading>
  </div>
</template>

<script>
import { addCoursePack, coursePackInfo } from '../api';
import { uploadFileToPublic } from '@/api/Common';

import BlockLoading from '@/components/BlockLoading';

const rules = {
  title: [
    {
      required: true,
      message: '请输入课程标题',
      whitespace: true,
    },
  ],
  summary: [{ required: true, message: '请输入课程包简介', whitespace: true }],
  url: [{ required: true, message: '请上传封面图片', whitespace: true }],
};
export default {
  components: { BlockLoading },
  data() {
    return {
      loading: false,
      data: {
        title: '',
        course_pack_type: '',
        summary: '',
        url: '',
        id: this.id,
      },
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      rules,
      id: this.$route.query?.id ?? 0,
      isSave: false,
    };
  },
  methods: {
    async getData() {
      this.loading = true;

      const data = await coursePackInfo({ pack_id: this.id }).finally(() => {
        this.loading = false;
      });

      if (data && data.error_code === 0) {
        if (data.data) {
          this.data.url = data.data[0].pack_pic;
          this.data.title = data.data[0].title;
          this.data.summary = data.data[0].summary;
          this.data.course_pack_type = data.data[0].course_pack_type;
          this.data.id = data.data[0].id;
        }
      }
    },
    async onUploadFileToPublic(file) {
      this.$message.info('正在上传');
      this.loading = true;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('source', 'course_task');
      const data = await uploadFileToPublic(formData).finally(() => {
        this.loading = false;
      });
      if (data && data.error_code === 0) {
        this.data.url = data.data.url;
        this.$message.success('上传成功');
      } else {
        this.$message.error('上传失败');
      }
    },
    //     handleChange(info) {
    //   if (info.file.status === "uploading") {
    //     this.loading = true;
    //     return;
    //   }
    //   if (info.file.status === "done") {
    //     this.loading = false;
    //     this.data.url = info.file.response?.data?.url;
    //     this.$message.success("上传成功");
    //   }
    // },
    async onAddCoursePack(data) {
      // eslint-disable-next-line no-redeclare
      this.loading = true;
      const resultData = await addCoursePack(data).finally(
        () => (this.loading = false),
      );
      if (resultData && resultData.error_code === 0) {
        let msg = this.data.id > 0 ? '修改成功' : '添加成功';
        this.data.id = resultData.data;
        // this.data = resultData.data;
        this.$message.success(msg);
        return Promise.resolve(this.data);
      } else {
        let msg = this.data.id > 0 ? '修改失败' : '添加失败';
        this.$message.error(msg, resultData.message);
        return Promise.reject(this.data);
      }
    },
    validate() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$refs.modelRef.validate((validator) => {
          if (validator) {
            resolve();
          } else {
            this.loading = false;
            reject();
          }
        });
      });
    },
    async save() {
      this.isSave = true;
      return new Promise((resolve, reject) => {
        this.validate().then(
          () => {
            this.onAddCoursePack(this.data).then(
              (result) => {
                return resolve(result);
              },
              () => {
                return reject();
              },
            );
          },
          () => {
            reject();
          },
        );
      });
    },
    async saveToNext() {
      await this.save().then((result) => {
        this.data.id = result.id;
        this.id = result.id;
        this.$router.push({
          path: 'course-list',
          query: {
            id: result.id,
            timeSpan: new Date().getTime(),
          },
        });
      });
    },
    saveTo() {
      this.save().then((result) => {
        if (result) {
          this.data.id = result.id;
          this.id = result.id;
          this.$router.push({
            path: 'create',
            query: {
              id: result.id,
              timeSpan: new Date().getTime(),
            },
          });
        }
      });
    },
    upLoadImg(file) {
      this.onUploadFileToPublic(file.file);
    },
    onBeforeUpload(file) {
      if (!(file.type.search('image') >= 0)) {
        this.$message.error('上传文件只支持图片');
        return false;
      }
    },
    onRemove() {
      this.data.url = '';
    },
    onBack() {
      if (this.isSave) {
        this.$router.push({
          path: '/index/course/course-package',
          query: {
            id: this.data.id,
          },
        });
        return;
      }
      this.$confirm({
        title: '确认提示',
        content: '当前数据未保存,是否返回列表',
        onOk: () => {
          this.$router.push({
            path: '/index/course/course-package',
            query: {
              id: this.data.id,
            },
          });
        },
      });
    },
    onChange() {
    },
  },
  mounted() {
    if (this.$route.query?.id > 0) {
      this.id = this.$route.query?.id ?? 0;
      this.data.id = this.id;
      this.getData();
    }
  },
  watch: {
    $route() {
      this.id = this.$route.query?.id ?? 0;
      this.data.id = this.id;
      if (this.$route.query?.id > 0) {
        this.getData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.course-package-create {
  width: 100%;

  .flex {
    display: flex;
    align-items: center;
    justify-items: center;

    span {
      white-space: nowrap;
    }
  }
}

.upload-img {
  width: 100%;
  text-align: center;
  height: 150px;
  line-height: 150px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
  }

  ::v-deep .ant-upload {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  ::v-deep textarea {
    width: 100%;
    display: block;
    height: 150px;
    padding: 0;
    margin: 0;
    border: 0;
  }
}
</style>
